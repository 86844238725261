import React from "react";
import { Link } from "react-router-dom";

import "../scss/ui/SquaredButton.scss";

export default function SquaredButton(props) {
  const iconSwitch = (type) =>
    ({
      back: (
        <path
          d="M600,189.5l-417.6-10.9L282.5,45.7L236.8,0L0,236.8l236.8,236.8l45.7-45.7L182.4,295.1l318.6-8.3l8.2,313.2h80.1L600,189.5
L600,189.5z"
        />
      ),
      home: (
        <path d="M64.3,578.6h155.9l11.5-159.6h137.1l11,159.6h155.9V300H64.3L64.3,578.6z M192.9,0L0,235.7h600L407.1,0H192.9z" />
      ),
    }[type]);

  const { type = "", label = "" } = props;

  return (
    <Link {...props} className={`squaredButton ${label ? "labeled" : ""}`}>
      {type && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          width="600px"
          height="600px"
          viewBox="0 0 600 600"
        >
          {iconSwitch(type)}
        </svg>
      )}
      {label && <label>{label}</label>}
    </Link>
  );
}
