import React from "react";
import { Link } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";

import "../scss/ui/Button.scss";
export default function Button(props) {
  const {
    link,
    disabled,
    badge,
    thumb,
    children,
    active = false,
    type = "default",
  } = props;

  return (
    <Link
      to={link}
      className={`button strong ${type} ${disabled ? "disabled" : ""} ${
        active ? "active" : ""
      }`}
      style={thumb && { backgroundImage: `url(${thumb})` }}
    >
      {badge && (
        <div className="badge">
          <div>{badge}</div>
        </div>
      )}
      <div className="type"></div>
      <div className="label">{ReactHtmlParser(children)}</div>
    </Link>
  );
}
