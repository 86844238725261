import React, { useEffect, useRef } from "react";

import "../scss/ui/Bubble.scss";

export default function Bubble(props) {
  const { children, avatar, arrow = "bottom" } = props;
  //const splitted = children.split("<br />");
  const inputEl = useRef(null);
  useEffect(() => {
    /* const list = inputEl.current.children; 
    for(const item of list){
     new CircleType(item).radius(700);
    } */
  }, []);
  return (
    <div className="bubble-align">
      <div className="bubble" data-arrow={arrow} ref={inputEl}>
        {avatar && (
          <div
            className="avatar"
            style={{ backgroundImage: `url(${avatar})` }}
          />
        )}
        <div className="bubble-inner">{children}</div>
      </div>
    </div>
  );
}
