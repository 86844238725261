import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";
import { withRouter } from "react-router-dom";

const StepNavigationButton = (props) => {
  const {
    children,
    link,
    subline,
    index,
    slug,
    pulse = false,
    type = "",
    hidden,
    url,
    onChange,
    match: {
      params: { step },
    },
  } = props;
  const active = step === slug;
  const [parsedSubline, setParsedSubline] = useState("");

  useEffect(() => {
    let interval = null;
    let sublineIndex = 0;

    if (Array.isArray(subline)) {
      setParsedSubline(subline[sublineIndex]);
      sublineIndex++;
      interval = setInterval(() => {
        if (sublineIndex < subline.length) {
          setParsedSubline(subline[sublineIndex]);
          sublineIndex++;
        } else {
          clearInterval(interval);
        }
      }, 3500);
    } else {
      setParsedSubline(subline);
    }
    return () => clearInterval(interval);
  }, [subline]);

  if(hidden) return (<></>);

  return (
    <>
    {type === "external" && (
      <a
        href={url}
        target="_blank"
        rel="noopener noreferrer"
        className={`StepNavigationButton ${pulse && "pulse"} ${
          active && "active"
        }`}
      >
        <i>{index}</i>
        {children} 
        {active && <span>{ReactHtmlParser(parsedSubline)}</span>}
      </a>
    )}
    {type !== "external" && (
      <Link
        to={type === "external" ? url : link}
        onClick={() => onChange()}
        className={`StepNavigationButton ${pulse && "pulse"} ${
          active && "active"
        }`}
      >
        <i>{index}</i>
        {children}
        {active && <span>{ReactHtmlParser(parsedSubline)}</span>}
      </Link>
    )}
    </>
  );
};
export default withRouter(StepNavigationButton);
