import React, { Component, createContext } from "react";
import axios from "axios";

export const DataContext = createContext();

export default class DataContextProvider extends Component {
  state = {
    config: {
      canvas: {},
    },
    branches: [],
  };
  componentDidMount() {
    axios.get(`/assets/data/main.json`).then(({ data }) => {
      this.setState({ ...data });
    });
  }
  render() {
    return (
      <DataContext.Provider value={{ ...this.state }}>
        {this.props.children}
      </DataContext.Provider>
    );
  }
}
