import React, { useContext, useState, useEffect } from "react";

import { UsecaseContext } from "../contexts/UsecaseContext";
import SquaredButton from "../ui/SquaredButton";
import { StepNavigation } from "../components/steps/StepNavigation";
import ReactHtmlParser from "react-html-parser";
import Plant from "../components/Plant";
import OverlayMedia from "../components/OverlayMedia";

import Bubble from "../ui/Bubble";
import Button from "../ui/Button";
import { Tooltips } from "../components/tooltips";
import { withRouter } from "react-router-dom";

const slugify = require("slugify");

const Usecase = (props) => {
  const {
    branch: branchSlug,
    usecase: usecaseSlug,
    step: stepSlug,
    substep: substepSlug,
  } = props;
  const usecase = useContext(UsecaseContext);
  const [dynamicSubline, setDynamicSubline] = useState("");
  const [mediaElement, setMediaElement] = useState(false);
  const [activeTooltips, setActiveTooltips] = useState([]);
  const [showButtons, setShowButtons] = useState(true);
  const [showAllSidenav, setShowAllSidenav] = useState(false);

  // ToDo: Clean Up
  const firstStep = (usecase.steps || [])[0] || { label: "" };
  const firstStepSlug = slugify(firstStep.label, {
    replacement: "-",
    lower: true,
  });

  const currentStep =
    (!stepSlug
      ? firstStep
      : (usecase.steps || []).find(({ label }) => {
          return (
            slugify(label, {
              replacement: "-",
              lower: true,
            }) === stepSlug
          );
        })) || {};
  const { substeps: substepData = [] } = currentStep;
  const substepIndex = substepData.findIndex(({ key }) => key === substepSlug);

  const {
    media: currentMedia = "",
    overlayMedia: currentOverlayMedia = "",
    mediaconfig,
    mediaComplete = {},
    overlayMediaComplete = {},
    subline = "",
    bubble = "",
    buttons,
    tooltips,
    sublineCuepoints,
    stepnavigationpulse = -1,
    config: { hideButtons = false } = {},
  } = substepData[substepIndex !== -1 ? substepIndex : 0] || {};
  useEffect(() => {
    setShowButtons(!hideButtons);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buttons]);

  useEffect(() => {
    setShowAllSidenav(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usecaseSlug]);

  useEffect(() => {
    if(substepSlug==="complete") setShowAllSidenav(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [substepSlug]);

  


  
  const [stepnavpulse, setStepnavigationpulse] = useState(stepnavigationpulse);

  const onMediaComplete = (isOverlay = false) => {
    const { action = "", stepnavigationpulse = -1 } = isOverlay
      ? overlayMediaComplete
      : mediaComplete;
    if (stepnavigationpulse !== -1) setStepnavigationpulse(stepnavigationpulse);
    if (action) {
      switch (action) {
        case "showButtons":
          return setShowButtons(true);
        case "showExternalLink":
          return setShowAllSidenav(true);
        default:
          const redirect = buttonAction(action);
          if (redirect) props.history.push(redirect);
          return;
      }
    }
  };

  const buttonAction = (action) => {
    // Find in same Step
    if (substepData.find(({ key }) => key === action))
      return `/${branchSlug}/${usecaseSlug}/${
        stepSlug || firstStepSlug
      }/${action}`;

    // Todo clean handler
    const match = action.match(/step-([0-9]+)/);
    if (match) {
      // REDIRECT
      const redirectSlug = slugify(usecase.steps[match[1]].label || "", {
        replacement: "-",
        lower: true,
      });

      return `/${branchSlug}/${usecaseSlug}/${redirectSlug}`;
      // props.history.push(redirectSlug);
    }
  };

  const findInherit = (usecase, inh) => {
    const steps = usecase.steps || [];
    let res = [];
    steps.forEach(({ substeps = [] }) => {
      substeps.forEach(({ key, tooltips }) => {
        if (key === inh) res = tooltips;
      });
      return res;
    });
    return res;
  };

  const tooltipsParsed = Array.isArray(tooltips)
    ? tooltips
    : findInherit(usecase, (tooltips || {}).inherit);

  const sublineCuepointsParsed = Array.isArray(sublineCuepoints)
    ? sublineCuepoints
    : findInherit(usecase, (sublineCuepoints || {}).inherit);

  return (
    <div className="Usecase">
      <Plant
        media={currentMedia}
        tooltips={tooltipsParsed}
        mediaconfig={mediaconfig}
        onMediaComplete={() => onMediaComplete()}
        onMediaLoaded={(media) => {
          setMediaElement(media);
          setActiveTooltips([]);
        }}
        onCueChange={({ cue: { text } }) => {
          setActiveTooltips([...activeTooltips, parseInt(text)]);
        }}
      ></Plant>
      <OverlayMedia
        media={currentOverlayMedia}
        sublineCuepoints={sublineCuepointsParsed}
        onMediaComplete={() => onMediaComplete(true)}
        onCueChange={({ cue: { text } }) => {
          setDynamicSubline(text);
        }}
      />
      <Tooltips
        mediaElement={mediaElement}
        tooltips={tooltipsParsed}
        activeTooltips={activeTooltips}
      />

      {bubble && <Bubble>{ReactHtmlParser(bubble)}</Bubble>}
      <div className="button-bar">
        {buttons &&
          showButtons &&
          buttons.map(({ label, type = "default", action }) => {
            const link = buttonAction(action);
            return (
              <Button
                link={link}
                type={type}
                active={action === substepSlug}
                action={action}
              >
                {label}
              </Button>
            );
          })}
      </div>
      <StepNavigation
        subline={dynamicSubline !== "" ? dynamicSubline : subline}
        {...props}
        pulse={stepnavpulse}
        showAll={showAllSidenav}
        onChange={() => {
          setStepnavigationpulse(-1);
          setDynamicSubline("");
        }}
      ></StepNavigation>
      <div className="squaredButtons">
        <SquaredButton type="back" to={`/${branchSlug}`} />
        <SquaredButton type="home" to="/industry" />
      </div>
    </div>
  );
};

export default withRouter(Usecase);
