import React, { Component, createContext } from "react";
import axios from "axios";

export const UsecaseContext = createContext();

export default class UsecaseContextProvider extends Component {
  state = {
    usecase: { steps: [] },
  };
  componentDidMount() {
    this.load();
  }
  componentDidUpdate(prevProps) {
    if (this.props.load !== prevProps.load) {
      this.load();
    }
  }
  load() {
    const { load: loadUrl = "" } = this.props;
    axios.get(loadUrl).then(({ data: { usecase } }) => {
      this.setState({ ...usecase });
    });
  }
  render() {
    return (
      <UsecaseContext.Provider value={{ ...this.state }}>
        {this.props.children}
      </UsecaseContext.Provider>
    );
  }
}
