import React from "react";
import Canvas from "./Canvas";

const Plant = (props) => {
  return (
    <div className="Plant">
      <Canvas {...props} />

      {/*<Button key={link} link={`${base}${link}`} badge={badge} disabled={disabled} thumb={thumb}>
                {name}
              </Button> */}
    </div>
  );
};

export default Plant;
