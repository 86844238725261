import React, { useState } from "react";
import Canvas from "../components/Canvas";
import "../scss/layout/Intro.scss";
import Bubble from "../ui/Bubble";
import SquaredButton from "../ui/SquaredButton";

export default function Intro() {
  const [complete, setComplete] = useState(false);
  return (
    <div className="Intro">
      <div className="inner">
        <Canvas
          className={`intro-video ${complete ? "" : "show"}`}

          media="/assets/video/intro.mp4"
          onMediaLoaded={() => {}}
          onMediaComplete={() => setComplete(true)}
        ></Canvas>
        <img
          className={complete ? "show" : ""}
          alt="intro-video"
          src="/assets/images/avatar/patrick-intro.png"
        ></img>
      </div>
      <div className={`bubble-outer ${complete ? "show" : ""}`}>
        <Bubble arrow="top">
          Hi, my name is Patrick – your Siemens Predictive Services Expert. Let's take a
          look into the future of your plant.
        </Bubble>
      </div>{" "}
      <div className="squaredButtons">
        <SquaredButton
          label={!complete ? "Skip" : "Continue"}
          to={`/industry`}
        />
      </div>
    </div>
  );
}
