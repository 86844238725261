import React, { useEffect } from "react";
import seeThru from "seethru";
let transVideo = null;

const CanvasVideo = React.forwardRef((props, ref) => {
  const { loop, onMediaComplete, onMediaLoaded, media, show } = props;
  const { video } = ref;
  useEffect(() => {
    transVideo = seeThru.create(video.current);
    transVideo.play();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  try {
    transVideo.getCanvas().className = `seeThru-hide ${show ? "show" : "hide"}`;
  } catch (error) {}
  return (
    <video
      className={show ? "show" : "hide"}
      ref={video}
      autoPlay
      loop={loop}
      muted
      onTimeUpdate={(e) => {
        if (
          loop &&
          e.currentTarget.currentTime > e.currentTarget.duration - 0.5
        ) {
          onMediaComplete();
        }
      }}
      onEnded={onMediaComplete}
      onCanPlay={({ currentTarget }) => {
        onMediaLoaded(transVideo.getCanvas());
        transVideo.play();
      }}
    >
      <source src={media} type="video/mp4" />
    </video>
  );
});
export default CanvasVideo;
