import React, { useContext } from "react";
import "../scss/layout/Usecase.scss";
import { DataContext } from "../contexts/DataContext";

import UsecaseContextProvider from "../contexts/UsecaseContext";
import Usecase from "./Usecase";
const slugify = require("slugify");

const UsecaseWrapper = (props) => {
  const { match: { params = {} } = {} } = props;
  const { branch: branchSlug, usecase: usecaseSlug } = params || {};
  const { branches } = useContext(DataContext);
  const { machines } = branches.find(
    ({ name }) =>
      slugify(name, {
        replacement: "-",
        lower: true,
      }) === branchSlug
  ) || { machines: [] };
  const { data: useCaseData } =
    machines.find(
      ({ name }) =>
        slugify(name, {
          replacement: "-",
          lower: true,
        }) === usecaseSlug
    ) || "";
  return (
    <div className="usecase">
      <UsecaseContextProvider load={useCaseData}>
        <Usecase {...params} />
      </UsecaseContextProvider>
    </div>
  );
};

export default UsecaseWrapper;
