import React, { Component } from "react";
import "../scss/components/OverlayMedia.scss";
import seeThru from "seethru";
let transVideo = null;

export default class OverlayMedia extends Component {
  constructor() {
    super();
    this.video = React.createRef();
    this.track = null;
    this.cuechange = this.cuechange.bind(this);
  }
  componentDidMount() {
    transVideo = seeThru.create(this.video.current);
    transVideo.play();
  }
  componentDidUpdate({ media }) {
    try {
      transVideo.getCanvas().className = `seeThru-hide ${
        media ? "show" : "hide"
      }`;
    } catch (error) {}
    if (this.props.media !== media) {
      try {
        this.video.current.load();
        this.addCues(this.video.current);
      } catch (error) {
        console.info("No Video to load");
      }
    }
  }

  addCues(currVideoElem) {
    // CLEAR OLD CUES
    try {
      this.track.cues.forEach((cue) => {
        this.track.removeCue(cue);
      });
    } catch (error) {}

    const { sublineCuepoints = [] } = this.props;
    this.track = currVideoElem.addTextTrack("metadata", "", "");
    this.track.removeEventListener("cuechange", this.cuechange);
    this.track.addEventListener("cuechange", this.cuechange);

    this.track.mode = "showing";
    sublineCuepoints.forEach(({ subline, visibleAt }) => {
      const cueCn = new VTTCue(visibleAt, visibleAt + 1, subline);
      this.track.addCue(cueCn);
    });
  }

  cuechange(event) {
    try {
      const cue = event.target.activeCues[0];
      if (cue) this.props.onCueChange({ cue });
    } catch (error) {
      console.warn(error);
    }
  }

  render() {
    const {
      media = "",
      mediaconfig: { loop = false } = {},
      onMediaComplete = () => {},
      onMediaLoaded = () => {},
    } = this.props;

    return (
      <div className="OverlayMedia">
        <video
          ref={this.video}
          autoPlay
          loop={loop}
          onEnded={onMediaComplete}
          onLoadedMetadata={({ currentTarget }) => {
            onMediaLoaded(currentTarget);
          }}
        >
          <source src={media} type={`video/${media.split(".").pop()}`} />
        </video>
      </div>
    );
  }
}
