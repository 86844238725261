import React, { useRef, useEffect } from "react";
import { gsap, TimelineMax, TweenLite, CSSPlugin } from "gsap";
import "../scss/ui/Point.scss";

gsap.registerPlugin(CSSPlugin);
const Point = () => {
  const point = useRef(null);
  const spinner = useRef(null);
  const spinner__ani = useRef(null);
  const dot = useRef(null);
  const exlamation = useRef(null);
  const exlamation__inner = useRef(null);

  useEffect(() => {
    const startAnimation = new TimelineMax({
      paused: false,
      onComplete: () => loopAnimation.play(),
    });
    const loopAnimation = new TimelineMax({
      paused: true,
      onComplete: () => loopAnimation.play("loop"),
    });
    TweenLite.fromTo(
      exlamation__inner.current,
      0.5,
      { opacity: 0 },
      { opacity: 1, yoyo: true, repeat: -1 }
    );
    startAnimation.add(
      TweenLite.fromTo(
        point.current,
        0.5,
        { scale: 0, opacity: 0 },
        { scale: 1, opacity: 1 }
      )
    );
    startAnimation.add(
      TweenLite.fromTo(spinner.current, 2, { rotate: "-90" }, { rotate: "270" })
    );

    startAnimation.add(
      TweenLite.fromTo(
        spinner__ani.current,
        2,
        { strokeDasharray: "0 158" },
        { strokeDasharray: "158 158" }
      ),
      "-=2"
    );
    startAnimation.add(
      TweenLite.fromTo(
        spinner__ani.current,
        0.5,
        { opacity: 1 },
        { opacity: 0 }
      )
    );
    loopAnimation.add([
      TweenLite.fromTo(dot.current, 0.5, { opacity: 1 }, { opacity: 0 }),
      TweenLite.fromTo(
        exlamation.current,
        0.25,
        { opacity: 0 },
        { opacity: 1 }
      ),
    ]);

    loopAnimation.add(
      TweenLite.set(spinner__ani.current, {
        opacity: 1,
        attr: {
          r: 45,
        },
      })
    );
    loopAnimation.add("loop");

    loopAnimation.add(
      TweenLite.fromTo(spinner.current, 2, { rotate: "-90" }, { rotate: "270" })
    );
    loopAnimation.add(
      TweenLite.fromTo(
        spinner__ani.current,
        1,
        { strokeDasharray: "0 282", strokeDashoffset: "0" }, //  2π × 25 ≈ 158
        { strokeDasharray: "141 282", strokeDashoffset: "-141", ease: "none" }
      ),
      "-=2"
    );
    loopAnimation.add(
      TweenLite.fromTo(
        spinner__ani.current,
        1,
        {
          strokeDasharray: "141 282",
          strokeDashoffset: "-141",
          immediateRender: false,
        },
        {
          strokeDasharray: "0 282",
          strokeDashoffset: "-282",
          ease: "none",
          immediateRender: false,
        }
      ),
      "-=1"
    );
  }, []);

  return (
    <div className="Point">
      <div ref={point}>
        {" "}
        <svg className="spinner" ref={spinner} width="75" height="75">
          <g className="spinner__g">
            <circle
              className="spinner__ani"
              ref={spinner__ani}
              r="25"
              cx="37.5"
              cy="37.5"
            />
          </g>
        </svg>
        <svg className="inner" width="75" height="75">
          <circle className="bg fill__red " cx="37.5" cy="37.5" r="37.5" />
          <path
            className="outer fill__red"
            d="M37.5,4C56,4,71,19,71,37.5S56,71,37.5,71S4,56,4,37.5C4,19,19,4,37.5,4 M37.5,0 C16.8,0,0,16.8,0,37.5S16.8,75,37.5,75S75,58.2,75,37.5C75,16.7,58.2,0,37.5,0L37.5,0z"
          />
          <path
            className="dot fill__red"
            ref={dot}
            d="M45,37.5c0,4.1-3.4,7.5-7.5,7.5S30,41.6,30,37.5s3.4-7.5,7.5-7.5S45,33.3,45,37.5z"
          />
          <g ref={exlamation}>
            <path
              className="exlamation fill__red"
              ref={exlamation__inner}
              d="M37.5,48.1c1.2,0,2.3,0.4,3.1,1.3c0.8,0.8,1.2,1.9,1.2,3.1s-0.4,2.3-1.2,3.1
c-0.8,0.9-1.9,1.3-3.2,1.3s-2.3-0.4-3.2-1.3c-0.8-0.8-1.2-1.9-1.2-3.1c0-1.3,0.4-2.3,1.2-3.1C35.2,48.6,36.3,48.1,37.5,48.1z
M40.8,44.4h-6.7V17.9h6.8L40.8,44.4z"
            />
          </g>
        </svg>
      </div>{" "}
    </div>
  );
};
export default Point;
