import React, { useContext, useState, useEffect, useRef } from "react";
import { DataContext } from "../contexts/DataContext";

import "../scss/components/Tooltips.scss";
import Point from "../ui/Point";

export const Tooltips = (props) => {
  const { tooltips = [], mediaElement = null, activeTooltips = [] } = props;
  const {
    config: { canvas: { width: cw = 1000, height: ch = 1000 } } = {},
  } = useContext(DataContext);
  const [xOffset, setXOffset] = useState(false);
  const [yOffset, setYOffset] = useState(false);
  const [box, setBox] = useState(false);
  const [scaleOffset, setScaleOffset] = useState(1);
  const gfxOuter = useRef(null);
  useEffect(() => {
    if (mediaElement) {
      // Todo Implement
      window.removeEventListener("resize", onResize);
      window.addEventListener("resize", onResize);
      onResize();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mediaElement]);

  //const [yOffset, setYOffset] = useState(false);

  const onResize = () => {
    const { offsetWidth: outerWidth, offsetHeight: outerHeight } =
      gfxOuter.current || {};
    const outerRatio = outerWidth / outerHeight;
    const mediaRatio = cw / ch;
    let xOffset = 0; //(width - 1024) / 2;
    let yOffset = 0; //(height - 884) / 2;
    let scale = 1;
    if (outerRatio > mediaRatio) {
      xOffset = outerWidth - mediaElement.clientHeight * mediaRatio;
      scale = outerHeight / ch;
    } else {
      yOffset = outerHeight - mediaElement.clientWidth / mediaRatio;
      scale = outerWidth / cw;
    }

    setXOffset(xOffset);
    setYOffset(yOffset);
    setScaleOffset(scale);
  };
  return (
    <div className="tooltips">
      <div className="tooltips-inner" ref={gfxOuter}>
        {box && (
          <div className="tooltip-box">
            <div>{box.content}</div>
            {box.subContent && (
              <ul>
                {box.subContent.map((elem) => (
                  <li>{elem}</li>
                ))}
              </ul>
            )}
          </div>
        )}

        <div
          className="tooltips-scale"
          style={{
            marginLeft: `${xOffset / 2}px`,
            marginTop: `${yOffset / 2}px`,
            width: `calc(100% - ${xOffset}px)`,
            height: `calc(100% - ${yOffset}px)`,
          }}
        >
          {tooltips.map(
            (tooltip, index) =>
              (activeTooltips.includes(tooltip.id) ||
                mediaElement.tagName === "IMG") && (
                <Tooltip
                  key={index}
                  scaleOffset={scaleOffset}
                  {...tooltip}
                  mouseOver={() => {
                    setBox({
                      content: tooltip.content,
                      subContent: tooltip.subContent,
                    });
                  }}
                  mouseOut={() => {
                    setBox(null);
                  }}
                />
              )
          )}
        </div>
      </div>
    </div>
  );
};

export const Tooltip = ({
  scaleOffset,
  content,
  y,
  x,
  mouseOver = () => {},
  mouseOut = () => {},
}) => {
  return (
    <div
      className="tooltip"
      style={{
        transform: `scale(${scaleOffset})`,
        left: `${x}%`,
        top: `${y}%`,
      }}
      onMouseOver={mouseOver}
      onMouseOut={mouseOut}
    >
      <Point />
    </div>
  );
};
