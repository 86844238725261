import React from "react";

import "../scss/components/BgVideo.scss";

export default function BgVideo() {
  return (
    <div className="fullscreen-bg">
      <video loop muted autoPlay className="fullscreen-bg__video">
        <source src="/assets/video/bg.mp4" type="video/mp4" />
      </video>
    </div>
  );
}
