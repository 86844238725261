import React, { useContext } from "react";
import ButtonBar from "../components/ButtonBar";
import Bubble from "../ui/Bubble";
import { DataContext } from "../contexts/DataContext";
import ReactHtmlParser from "react-html-parser";
import SquaredButton from "../ui/SquaredButton";
import "../scss/layout/Branch.scss";

const slugify = require("slugify");

const Branch = (props) => {
  const { match: { params: { branch: branchSlug } = {} } = {} } = props;
  // Filter Data
  const { branches } = useContext(DataContext);
  const branchData =
    branches.find(
      ({ name }) =>
        slugify(name, {
          replacement: "-",
          lower: true,
        }) === branchSlug
    ) || {};
  const { machines, bubble = {}, plant } = branchData;
  return (
    <div className="branch">
      <Bubble avatar={bubble.avatar}>{ReactHtmlParser(bubble.text)}</Bubble>
      {plant && <img alt="Plant" className="plant" src={plant} />}
      <ButtonBar base={`${branchSlug}/`} data={machines} />
      <div className="squaredButtons">
        <SquaredButton type="back" to="/industry" />
      </div>
    </div>
  );
};

export default Branch;
