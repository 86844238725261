import React from "react";
import "../scss/layout/MobileInfo.scss";

const MobileInfo = () => {
  return (
    <div className="MobileInfo">
      <div className="useDesktop">
        <div className="icon"></div>
        <div className="infoText">
          Please use a desktop computer to explore Siemens Predictive Services.
        </div>
      </div>

      <div className="rotateDevice">
        <div className="icon"></div>
        <div className="infoText">
          Please turn your tablet 90 degrees to explore the Siemens Predictive
          Services.
        </div>
      </div>
    </div>
  );
};

export default MobileInfo;
