import React from "react";
import "./App.scss";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import BgVideo from "./components/BgVideo";

import DataContextProvider from "./contexts/DataContext";
import Intro from "./layout/Intro.js";
import Main from "./layout/Main.js";
import Branch from "./layout/Branch.js";
import UsecaseWrapper from "./layout/UsecaseWrapper";
import MobileInfo from "./layout/MobileInfo";
import Logo from "./ui/Logo";

function App() {
  return (
    <div className="App">
      <Logo />
      <BgVideo />
      <DataContextProvider>
        <BrowserRouter>
          <Switch>
            <Route exact path="/" component={Intro} />
            <Route exact path="/industry" component={Main} />
            <Route
              exact
              path={`/:branch`}
              render={(props) => <Branch {...props} />}
            />
            <Route
              path={`/:branch/:usecase/:step?/:substep?`}
              render={(props) => <UsecaseWrapper {...props} />}
            />
          </Switch>
        </BrowserRouter>
      </DataContextProvider>
      <MobileInfo></MobileInfo>
    </div>
  );
}

export default App;
