import React from "react";

import Button from "../ui/Button";
import "../scss/components/ButtonBar.scss";

const slugify = require("slugify");

const ButtonBar = ({ data, base = "" }) => {
  return (
    <div>
      <div className="button-bar">
        {data &&
          data.map(({ name, badge, disabled, thumb }) => {
            const link = slugify(name, {
              replacement: "-",
              lower: true,
            });
            return (
              <Button
                key={link}
                link={`${base}${link}`}
                badge={badge}
                disabled={disabled}
                thumb={thumb}
              >
                {name}
              </Button>
            );
          })}
      </div>
    </div>
  );
};

export default ButtonBar;
