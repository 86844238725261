import React, { Component } from "react";
import CanvasVideo from "./CanvasVideo";

export default class Canvas extends Component {
  constructor() {
    super();
    this.image = React.createRef();

    this.video = React.createRef();
    this.video_1 = React.createRef();

    this.cuetrack_0 = React.createRef();
    this.cuetrack_1 = React.createRef();
    this.imageLoaded = this.imageLoaded.bind(this);
    this.cuechange = this.cuechange.bind(this);
    this.state = {
      showImage: true,
      currVideo: false,
      showVideo: true,
      showLoading: false,
    };
  }

  componentDidUpdate({ media }) {
    if (this.props.media !== media) this.changeMedia(this.props);
  }

  componentDidMount() {
    this.changeMedia(this.props);
  }

  changeMedia({ media }) {
    try {
      if (media.split(".").pop() === "mp4") {
        this.changeVideo();
      } else {
        this.changeImage();
      }
    } catch (error) {
      console.info(error, "No Video to load");
    }
  }

  changeVideo() {
    const currVideoElem = this.video.current;
    if (currVideoElem) {
      this.setState({ showLoading: true });

      currVideoElem.load();
      currVideoElem.addEventListener("canplay", () => {
        try {
          //  (!currVideo ? this.video_0 : this.video_1).current.pause();
        } catch (error) {
          console.warn("no video to stop");
        }
        this.setState({
          showLoading: false,
          showImage: false,
          showVideo: true,
        });
        this.addCues(currVideoElem);
      });
    }
  }

  addCues(currVideoElem) {
    const { tooltips = [] } = this.props;
    const track = currVideoElem.addTextTrack("metadata", "", "");
    track.removeEventListener("cuechange", this.cuechange);
    track.addEventListener("cuechange", this.cuechange);

    track.mode = "showing";
    tooltips.forEach(({ id, visibleAt }) => {
      const cueCn = new VTTCue(visibleAt, visibleAt + 1, id);
      track.addCue(cueCn);
    });
  }

  changeImage() {
    this.setState({ showImage: true, showLoading: true });
    this.image.current.removeEventListener("load", this.imageLoaded);
    this.image.current.addEventListener("load", this.imageLoaded);
  }
  imageLoaded(event) {
    this.setState({ showVideo: false, showLoading: false, showImage: true });
    this.video.current.pause();
  }
  cuechange(event) {
    try {
      const cue = event.target.activeCues[0];
      if (cue) this.props.onCueChange({ cue });
    } catch (error) {
      console.warn(error);
    }
  }
  render() {
    const {
      media = "",
      mediaconfig: { loop = false } = {},
      onMediaComplete,
      onMediaLoaded,
    } = this.props;

    const { showLoading, showImage, showVideo } = this.state;
    return (
      <>
        <div className={`lds-ring ${showLoading ? "" : "hidden"}`}>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <div className={`centered ${this.props.className}`}>
          <img
            src={media}
            alt="Media"
            onLoad={({ currentTarget }) => {
              onMediaLoaded(currentTarget);
            }}
            className="ratioHelper"
          />
          {media.split(".").pop() === "png" && (
            <img
              ref={this.image}
              src={media}
              alt="Media"
              className={
                media.split(".").pop() !== "mp4" && showImage ? "show" : "hide"
              }
              onLoad={({ currentTarget }) => {
                onMediaLoaded(currentTarget);
              }}
            />
          )}
          <CanvasVideo
            ref={{ video: this.video }}
            show={showVideo}
            media={media}
            loop={loop}
            onMediaComplete={onMediaComplete}
            onMediaLoaded={onMediaLoaded}
          />
        </div>
        {/*<CanvasVideo
          ref={{ video: this.video_1, cuetrack: this.cuetrack_1 }}
          show={showVideo1}
          media={media}
          loop={loop}
          onMediaComplete={onMediaComplete}
          onMediaLoaded={onMediaLoaded}
        /> */}
      </>
    );
  }
}
