import React, { useContext } from "react";
import { UsecaseContext } from "../../contexts/UsecaseContext";
import StepNavigationButton from "./StepNavigationButton";
import "../../scss/components/Steps.scss";
const slugify = require("slugify");

export const StepNavigation = ({
  branch: branchSlug,
  usecase: usecaseSlug,
  onChange: onChangeStepNav,
  match,
  showAll = false,
  subline,
  pulse = -1,
}) => {
  const { steps = [] } = useContext(UsecaseContext);
  return (
    <div className="StepNavigation">
      {steps.map(({ label, type="", url, visibleAt, config: { routeSlugs = false } = {} }, index) => {
        const slug = slugify(label, {
          replacement: "-",
          lower: true,
        });
        const { url:matchUrl, params: { substep = "" } = {} } = match;
        return (
          <StepNavigationButton
            link={`/${branchSlug}/${usecaseSlug}/${slug}${
              routeSlugs ? `/${substep}` : ""
            }`}
            onChange={() => onChangeStepNav()}
            pulse={pulse === index}
            slug={slug}
            subline={subline}
            key={index}
            type={type}
            url={url}
            index={index + 1}
            hidden={(visibleAt && !showAll) ? (visibleAt!==matchUrl) : false}
          >
            {label}
          </StepNavigationButton>
        );
      })}
    </div>
  );
};
