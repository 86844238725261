import React, { useContext } from "react";
import Bubble from "../ui/Bubble";
import ButtonBar from "../components/ButtonBar";
import { DataContext } from "../contexts/DataContext";

export default function Main() {
  const { branches } = useContext(DataContext);
  return (
    <div className="main">
      <Bubble avatar="/assets/images/avatar/patrick.jpg">
        What industry interests you?
      </Bubble>
      <ButtonBar data={branches} />
    </div>
  );
}
